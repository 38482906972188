import { Controller } from "stimulus"

export default class extends Controller {
	connect(){
		document.addEventListener("turbo:before-visit", function(e) {document.body.classList.add('waiting');}, {once: true});
		document.addEventListener("turbo:load", function(e) {document.body.classList.remove('waiting');}, {once: true});
	}

}

